// Resources
import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// Components
// import ReaderWrapper from "./components/body/ReaderWrapper";
import EditorWrapper from "./components/body/EditorWrapper";
import TextareaAutosize from "react-textarea-autosize";
import Select from "react-select";

// Auth
import AuthHelperMethods from "./components/auth/AuthHelperMethods";
import StyleGuide from "./StyleGuide";

class ArticleWrapper extends Component {
	Auth = new AuthHelperMethods();
	constructor(props) {
		super(props);
		this.state = {
			savedArticle: {},
			currentArticle: {},
			logout: false,
			redirect: "",
			lastScrollY: window.scrollY,
			activeHeadingIndex: -1,
			selectedEditor: ""
		};
		this.keyboardShortcutListener = this.keyboardShortcutListener.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
	}

	editArticle = (post_data) => {
		this.Auth.editArticle({ user: this.props.user, data: post_data })
			.then((res) => {
				const { match } = this.props;
				const { slug } = match.params;
				if (!res.success) {
					return alert(res.message);
				}
				if (slug && slug !== res.article.slug) {
					this.props.history.push("/wiki/" + res.article.slug);
					localStorage.setItem('refreshWikiAll', 'true');
				}
				this.setState({
					savedArticle: res.article,
					currentArticle: res.article
				});
				console.log("Article edits saved");
			})
			.catch((err) => {
				console.log("[ editArticle ] POST error:", err);
			});
	};

	handleSave = (publish) => {
		if (publish) {
			return this.editArticle({
				...this.state.currentArticle,
				status: "published"
			});
		}
		this.editArticle(this.state.currentArticle);
	};

	handleStatusChange = (e) => {
		this.setState({
			currentArticle: {
				...this.state.currentArticle,
				status: e.target.value
			}
		});
	};

	handleTitleChange = (e) => {
		this.setState({
			currentArticle: {
				...this.state.currentArticle,
				title: e.target.value.replace(/[\n]/gi, "").substring(0, 100)
			}
		});
	};

	handleCategoryChange = (e) => {
		this.setState({
			currentArticle: {
				...this.state.currentArticle,
				category: Array.from(e.target.selectedOptions, (option) => option.value).join(", ")
			}
		});
	};

	reportDraftChange = (content) => {
		this.setState({
			currentArticle: {
				...this.state.currentArticle,
				content: content
			}
		});
	};

	getContent(article) {
		var content = JSON.parse(article.content);
		var articleContent = "";
		if (content && content.blocks) {
			articleContent = content.blocks.map((a) => a.text).join(" ");
		}
		return articleContent;
	}

	getMailTo(article) {
		if (!article || !article.title || !article.content) return "";
		let subject = article.title;
		let body = "";
		body += "A new article has been published\n\n";
		body += `${article.title}\n\n`;
		var content = JSON.parse(article.content);
		var articleContent = "";
		if (content && content.blocks) {
			articleContent = content.blocks.map((a) => a.text).join(" ");
		}
		body += articleContent.substring(0, 100) + "...";
		body += `\n\nhttp://docs.upsellit.com/wiki/${article.slug}`;
		let address = "internal@upsellit.com";
		return `mailto:${address}?subject=${subject}&body=${encodeURIComponent(body)}&cc=`;
	}

	handleChildArticleUp = (child_article) => {
		if (!this.state.currentArticle.child_articles) return;
		const newIndex = Math.max(this.state.currentArticle.child_articles.indexOf(child_article) - 1, 0);
		this.setState((prevState) => {
			let newChildArticles = [...prevState.currentArticle.child_articles];
			const index = newChildArticles.indexOf(child_article);
			if (index > 0) {
				[newChildArticles[index], newChildArticles[newIndex]] = [
					newChildArticles[newIndex],
					newChildArticles[index]
				];
			}
			return {
				currentArticle: {
					...prevState.currentArticle,
					child_articles: newChildArticles
				}
			};
		});
	};

	handleChildArticleDown = (child_article) => {
		if (!this.state.currentArticle.child_articles) return;
		const oldIndex = this.state.currentArticle.child_articles.indexOf(child_article);
		const newIndex = Math.min(oldIndex + 1, this.state.currentArticle.child_articles.length - 1);
		this.setState((prevState) => {
			let newChildArticles = [...prevState.currentArticle.child_articles];
			if (oldIndex < newChildArticles.length - 1) {
				[newChildArticles[oldIndex], newChildArticles[newIndex]] = [
					newChildArticles[newIndex],
					newChildArticles[oldIndex]
				];
			}
			return {
				currentArticle: {
					...prevState.currentArticle,
					child_articles: newChildArticles
				}
			};
		});
	};

	handleChildArticleDelete = (child_article) => {
		this.setState({
			currentArticle: {
				...this.state.currentArticle,
				child_articles: this.state.currentArticle.child_articles.filter((a) => child_article !== a)
			}
		});
	};

	handleChildArticleAdd = (e) => {
		e.preventDefault();
		if (!this.state.currentArticle.child_articles) return;
		if (!e.target["child_articles"].value.trim()) return;
		let child_articles = [...this.state.currentArticle.child_articles, e.target["child_articles"].value.trim()];
		e.target["child_articles"].value = "";
		this.setState({
			currentArticle: {
				...this.state.currentArticle,
				child_articles: child_articles
			}
		});
	};

	handleEditorChange = (selectedOption) => {
		console.log("Editor selected:", selectedOption.value);
		this.setState({
			selectedEditor: selectedOption.value,
			currentArticle: {
				...this.state.currentArticle,
				created_by: selectedOption.value
			}
		});
	};

	handleTagDelete = (tag) => {
		this.setState({
			currentArticle: {
				...this.state.currentArticle,
				tags: this.state.currentArticle.tags.filter((a) => tag !== a)
			}
		});
	};

	handleTagAdd = (e) => {
		e.preventDefault();
		let tags = [e.target["tags-search"].value.toUpperCase().trim(), ...this.state.currentArticle.tags];
		e.target["tags-search"].value = "";
		this.setState({
			currentArticle: {
				...this.state.currentArticle,
				tags: tags
			}
		});
	};

	angleIcon = (
		<svg width="10px" height="10px" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="currentColor" />
		</svg>
	);

	renderSubOutline = (article, first_run) => {
		//const uniqueSlugs = new Set();
		let childOutlines = !article.child_articles
			? []
			: article.child_articles
					.map((slug) => this.props.articles.find((a) => a.slug === slug))
					.filter((a) => a)
					.map((a) => ({
						...a,
						outline: this.props.generateDocumentOutline(a.content)
					}));
		return (
			<details key={article.slug}>
				<summary className="article-title">
					{this.getContent(article) ? (
						<a href={"/wiki/" + article.slug} style={{ textDecoration: "none" }}>
							{article.title}
						</a>
					) : (
						<span>{article.title}</span>
					)}
					<span className="btn btn-link" style={{ padding: "0 1em" }}>
						{this.angleIcon}
					</span>

					{first_run && this.props.editMode && (
						<div>
							<button
								style={{ fontSize: "0.5em" }}
								className="close-button up-button tag-btn"
								aria-label="Remove tag"
								onClick={() => this.handleChildArticleUp(article.slug)}
							>
								↑
							</button>
							<button
								style={{ fontSize: "0.5em" }}
								className="close-button down-button tag-btn"
								aria-label="Remove tag"
								onClick={() => this.handleChildArticleDown(article.slug)}
							>
								↓
							</button>
							<button
								style={{ fontSize: "0.5em" }}
								className="close-button"
								aria-label="Remove tag"
								onClick={() => this.handleChildArticleDelete(article.slug)}
							>
								&times;
							</button>
						</div>
					)}
				</summary>
				{article.outline && article.outline.length > 0 && (
					<ul>
						{article.outline
							// .filter((item) => {
							// 	if (uniqueSlugs.has(item.slug)) {
							// 		return false; // Skip this item if the slug is already in the set
							// 	} else {
							// 		uniqueSlugs.add(item.slug); // Add slug to the set
							// 		return true;
							// 	}
							// })
							.map((item) => (
								<li className={item.type} key={"/wiki/" + article.slug + "#goto_" + this.props.toSlug(item.text)}>
									<a href={"/wiki/" + article.slug + "#goto_" + this.props.toSlug(item.text)}>
										{item.text}
									</a>
								</li>
							))}
					</ul>
				)}
				<div style={{ paddingLeft: "1em" }}>
					{childOutlines.map(
						(childOutlineItem, i) =>
							childOutlineItem.outline &&
							childOutlineItem.slug &&
							childOutlineItem.child_articles &&
							(childOutlineItem.child_articles.length > 0 || childOutlineItem.outline.length > 0 ? (
								this.renderSubOutline(childOutlineItem)
							) : (
								<div style={{ padding: "0.5em", borderLeft: "2px solid transparent" }} key={i}>
									{this.getContent(childOutlineItem) ? (
										<a
											className={""}
											style={{ textDecoration: "none" }}
											href={"/wiki/" + childOutlineItem.slug}
										>
											{childOutlineItem.title}
										</a>
									) : (
										<span key={i}>{childOutlineItem.title}</span>
									)}
								</div>
							))
					)}
				</div>
			</details>
		);
	};

	renderOutline = (outlineItems, article) => {
		let childOutlines = !article.child_articles
			? []
			: article.child_articles
					.map((slug) => this.props.articles.find((a) => a.slug === slug))
					.filter((a) => a)
					.map((a) => ({
						...a,
						outline: this.props.generateDocumentOutline(a.content)
					}));

		const { selectedOption } = this.state;
		const options = this.props.articles.map((article) => ({
			value: article.slug,
			label: article.title
		}));
		return (
			<div>
				{outlineItems && outlineItems.length > 0 && (
					<details open>
						<summary className="article-title">
							{" "}
							{article.title}
							<span className="btn btn-link" style={{ padding: "0 1em" }}>
								{this.angleIcon}
							</span>
						</summary>
						<ul>
							{outlineItems.map((item, index) => (
								<li className={item.type} key={index}>
									<a
										className={
											window.location.hash === "#goto_" + this.props.toSlug(item.text)
												? "active"
												: ""
										}
										href={"#goto_" + this.props.toSlug(item.text)}
									>
										{item.text}
									</a>
								</li>
							))}
						</ul>
					</details>
				)}

				{this.props.editMode && (
					<div className="child-articles-list">
						<form onSubmit={(e) => this.handleChildArticleAdd(e)}>
							<label>Child Articles</label>
							<Select
								value={selectedOption}
								options={options}
								placeholder="Add child article"
								isSearchable={true}
								name="child_articles"
							/>
							<button
								type="submit"
								aria-label="Add Child Article"
								className="add-button"
								style={{ padding: "0.75em" }}
							>
								+
							</button>
						</form>
					</div>
				)}
				{childOutlines.map(
					(childOutlineItem, i) =>
						childOutlineItem.outline &&
						childOutlineItem.slug &&
						(childOutlineItem.outline.length > 0 || (childOutlineItem.child_articles && childOutlineItem.child_articles.length > 0) ? (
							this.renderSubOutline(childOutlineItem, true)
						) : (
							<div style={{ padding: "0.5em", borderLeft: "2px solid transparent" }}>
								{this.getContent(childOutlineItem) ? (
									<a style={{ textDecoration: "none" }} href={"/wiki/" + childOutlineItem.slug}>
										{childOutlineItem.title}
									</a>
								) : (
									<span>{childOutlineItem.title}</span>
								)}
								{this.props.editMode && (
									<div>
										<button
											style={{ fontSize: "0.5em" }}
											className="close-button up-button tag-btn"
											aria-label="Up tag"
											onClick={() => this.handleChildArticleUp(childOutlineItem.slug)}
										>
											↑
										</button>
										<button
											style={{ fontSize: "0.5em" }}
											className="close-button down-button tag-btn"
											aria-label="Down tag"
											onClick={() => this.handleChildArticleDown(childOutlineItem.slug)}
										>
											↓
										</button>
										<button
											style={{ fontSize: "0.5em" }}
											className="close-button"
											aria-label="Remove tag"
											onClick={() => this.handleChildArticleDelete(childOutlineItem.slug)}
										>
											&times;
										</button>
									</div>
								)}
							</div>
						))
				)}
			</div>
		);
	};

	keyboardShortcutListener(event) {
		// "command + s" to save all fields. Key code 83 is "s"
		if (event.metaKey || event.ctrlKey) {
			if (event.key === "s" || event.keyCode === 83) {
				// save all fields "CTRL/CMD + S"
				event.preventDefault();
				this.handleSave(false);
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.location.pathname !== this.props.location.pathname) {
			// TODO: fix editor content loading issue. Temporary fix
			window.location.href = this.props.location.pathname;
		}
	}

	getArticle(slug) {
		this.Auth.fetchWikiArticle({ user: this.props.user, slug: slug })
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else {
					let article = res.data.articles[0];
					this.setState({
						savedArticle: article,
						currentArticle: article,
						revisions: res.data.articles
					});
				}
			})
			.catch((err) => {
				console.log("[ addArticle ] POST error:", err);
			});
	}

	handleBeforeUnload = (event) => {
		if (JSON.stringify(this.state.savedArticle) !== JSON.stringify(this.state.currentArticle)) {
			const message = "You have unsaved changes. Are you sure you want to leave?";
			event.returnValue = message; // Standard for most browsers
			return message; // For some older browsers
		}
	};

	saveRecentHistory = (slug) => {
		let history = localStorage["wikiHistory"] || [];
		if (history.length > 0) {
			history = JSON.parse(history).filter((item) => item !== slug);
		}
		history.push(slug);
		localStorage.setItem("wikiHistory", JSON.stringify(history));
	};

	handleScroll() {
		const headings = document.querySelectorAll(
			"h1 > div[id], h2 > div[id], h3 > div[id], h4 > div[id], .article-head[id]"
		);
		const top_navigation = document.querySelector(".top-navigation");
		const { lastScrollY } = this.state;
		const currentScrollY = window.scrollY;
		let activeHeadingIndex = this.state.activeHeadingIndex;
		const isScrollingDown = currentScrollY > lastScrollY;

		if (isScrollingDown) {
			if (top_navigation && top_navigation.style.top !== "-4em") {
				top_navigation.style.top = "-4em";
			}
			for (let i = 0; i < headings.length; i++) {
				const heading = headings[i];
				const rect = heading.getBoundingClientRect();
				if (rect.top >= 0 && rect.top <= 100) {
					activeHeadingIndex = i;
					break;
				}
			}
		} else {
			if (top_navigation && top_navigation.style.top !== "0") {
				top_navigation.style.top = "0";
			}
			let foundHeadingAboveViewport = false;
			for (let i = headings.length - 1; i >= 0; i--) {
				const heading = headings[i];
				const rect = heading.getBoundingClientRect();
				if (rect.bottom < 0 && !foundHeadingAboveViewport) {
					activeHeadingIndex = i;
					foundHeadingAboveViewport = true;
				} else if (rect.top >= 0 && foundHeadingAboveViewport) {
					break;
				}
			}
		}
		if (activeHeadingIndex !== this.state.activeHeadingIndex) {
			this.setState({ activeHeadingIndex, lastScrollY: currentScrollY });
			if (activeHeadingIndex !== -1) {
				const id = headings[activeHeadingIndex].id;
				if (id) {
					window.history.replaceState(null, null, `#${id}`);
				}
			}
		} else {
			this.setState({ lastScrollY: currentScrollY });
		}
	}

	componentWillUnmount = () => {
		document.body.removeEventListener("keydown", this.keyboardShortcutListener);
		window.removeEventListener("beforeunload", this.handleBeforeUnload);
		window.removeEventListener("scroll", this.handleScroll);
	};

	componentDidMount = () => {
		const { match } = this.props;
		const { slug } = match.params;
		this.getArticle(slug);
		this.saveRecentHistory(slug);
		document.body.addEventListener("keydown", this.keyboardShortcutListener);
		window.addEventListener("beforeunload", this.handleBeforeUnload);
		const goToHeading = () => {
			if (window.location.hash && !window.did_scroll) {
				const element = document.querySelector(window.location.hash);
				if (element) {
					setTimeout(() => element.scrollIntoView({ behavior: "smooth" }), 100);
					window.did_scroll = true;
				} else {
					return setTimeout(goToHeading, 500);
				}
			}
			var usp = new URLSearchParams(window.location.search);
			if (document.querySelector(".article-body") && usp.get("search")) {
				// Escape special characters in the search term for use in a RegExp
				var safeSearchTerm = usp.get("search").replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
				var searchRegex = new RegExp(safeSearchTerm, "i");

				// Check headings first
				const foundHeading = Array.from(
					document.querySelectorAll("h1 > div[id], h2 > div[id], h3 > div[id], h4 > div[id], h5 > div[id]")
				).find((heading) => searchRegex.test(heading.textContent));
				if (foundHeading && foundHeading.id) {
					window.location.hash = "#" + foundHeading.id;
				} else {
					// Search and scroll to the first instance of the search term in other text
					var walker = document.createTreeWalker(
						document.querySelector(".article-body"),
						NodeFilter.SHOW_TEXT,
						{
							acceptNode: function (node) {
								return searchRegex.test(node.nodeValue)
									? NodeFilter.FILTER_ACCEPT
									: NodeFilter.FILTER_SKIP;
							}
						}
					);

					var node = walker.nextNode();
					if (node && node.parentElement) {
						setTimeout(() => node.parentElement.scrollIntoView({ behavior: "auto" }), 100);
					}
				}

				// Update URL to remove search query
				usp.delete("search");
				var newUrl = window.location.pathname + (usp.toString() ? "?" + usp.toString() : "");
				window.history.replaceState(null, "", newUrl);
			}
			setTimeout(() => window.addEventListener("scroll", this.handleScroll), 500);
		}
		setTimeout(goToHeading, 1000);
	};

	render() {
		let article = this.state.currentArticle;
		let documentOutline, linkedArticles;
		if (article && typeof article.content != "undefined") {
			documentOutline = this.props.generateDocumentOutline(article.content);
			linkedArticles = [...new Set(article.content.match(/(?<="href":"|'href':')(.*?)(?="|')/g))];
		}
		const relatedArticles = this.props.articles.filter(
			(a) => a.title !== "Navigation" && a.content.match("/wiki/" + article.slug)
		);
		const options = this.props.articles.map((article) => ({
			value: article.slug,
			label: article.title
		}));
		return (
			<React.Fragment>
				<Helmet>
					<title>{article && article.title ? article.title + " | Upsellit Wiki" : ""}</title>
				</Helmet>
				<div className={"body-wrapper"}>
					{article && typeof article.content != "undefined" && (
						<main className={"article-view " + (this.props.editMode ? "edit-mode" : "reader-mode")}>
							<nav className="article-outline">{this.renderOutline(documentOutline, article)}</nav>
							<header className="article-head">
								<TextareaAutosize
									value={article.title}
									readOnly={!this.props.editMode}
									onChange={this.handleTitleChange}
								/>
								<div>
									{!this.props.editMode &&
										this.props.articles &&
										this.props.articles.filter(
											(a) => a.child_articles && a.child_articles.indexOf(article.slug) !== -1
										).length > 0 && (
											<ul
												style={{
													letterSpacing: "1px",
													textTransform: "uppercase",
													marginTop: "1em"
												}}
											>
												{(() => {
													const breadcrumbs = this.props.articles
														.filter(
															(a) =>
																a.child_articles &&
																a.child_articles.includes(article.slug)
														)
														.map((a) => {
															let breadcrumb = [a];
															let c = a;
															const getParentArticle = (slug) =>
																this.props.articles.find(
																	(parent) =>
																		parent.child_articles &&
																		parent.child_articles.includes(slug)
																);

															// Traverse up the hierarchy to build the breadcrumb trail
															while (c && c.child_articles) {
																const parentArticle = getParentArticle(c.slug);
																if (parentArticle) {
																	breadcrumb.unshift(parentArticle);
																	c = parentArticle; // Move to the next level up
																} else {
																	break; // Break if no further parent is found
																}
															}
															return breadcrumb;
														});

													const longestBreadcrumb = breadcrumbs.reduce((longest, current) => {
														return current.length > longest.length ? current : longest;
													}, []);

													return (
														<li>
															{longestBreadcrumb.length > 0 && (
																<div>
																	{longestBreadcrumb.map((b, index) => (
																		<React.Fragment key={b.slug}>
																			<a
																				className="btn btn-link"
																				style={{ padding: "0.5em 0" }}
																				href={"/wiki/" + b.slug}
																			>
																				{b.title}
																			</a>
																			<span className="btn btn-link">›</span>
																		</React.Fragment>
																	))}
																</div>
															)}
														</li>
													);
												})()}
											</ul>
										)}
								</div>
							</header>
							<article className="article-body">
								<EditorWrapper
									handleKeyCommand={this.handleKeyCommand}
									reportDraftChange={this.reportDraftChange}
									article={article}
									readOnly={!this.props.editMode}
									articles={this.props.articles}
								/>
							</article>
							{article.slug === "style_guide" && <StyleGuide />}
							<aside className="article-aside">
								<div className="button-list">
									{!this.props.editMode && article && (
										<a
											className="btn btn-secondary"
											href={this.getMailTo(article)}
											target="_blank"
											rel="noreferrer"
										>
											Send As Email
										</a>
									)}
								</div>
								{this.props.editMode && (
									<div className="article-status">
										<label>Status</label>
										<select
											value={article.status}
											disabled={!this.props.editMode}
											onChange={this.handleStatusChange}
										>
											<option value="unpublished">Unpublished</option>
											<option value="published">Published</option>
											<option value="archived">Archived</option>
										</select>
									</div>
								)}
								<div className="article-categories">
									{this.props.editMode && (
										<div className="article-categories-select">
											<label>Categories</label>
											<select
												value={article.category.split(", ")}
												disabled={!this.props.editMode}
												onChange={this.handleCategoryChange}
												className="input"
												aria-label="Change Category"
												multiple
											>
												{Object.keys(this.props.departments).map((c) => (
													<option key={c} value={c}>
														{c}
													</option>
												))}
											</select>
										</div>
									)}
									{this.props.editMode && (
										<form onSubmit={(e) => this.handleTagAdd(e)}>
											<label>Tags</label>
											<br />
											<input
												list="tags"
												type="search"
												placeholder="Add tag"
												id="tags-search"
												name="tags-search"
											/>{" "}
											<button type="submit" aria-label="Add Tag" className="add-button">
												+
											</button>
											<datalist id="tags">
												{this.props.tags.map((t) => (
													<option
														key={t}
														value={t}
														className={t.indexOf(".") !== -1 ? "disabled" : ""}
													>
														{t}
													</option>
												))}
											</datalist>
										</form>
									)}
									<div className="article-categories-list">
										{article.tags &&
											article.tags.map((t) => (
												<span key={t} className="tag-btn">
													{t}{" "}
													{this.props.editMode && (
														<button
															className="close-button"
															aria-label="Remove tag"
															onClick={() => this.handleTagDelete(t)}
														>
															&times;
														</button>
													)}
												</span>
											))}
									</div>
									{this.props.editMode && this.props.editors && (
										<div className="article-editor-select">
											<form>
												<label>Managing Editor</label>
												<Select
													value={options.find(
														(option) => option.value === article.created_by
													)}
													onChange={this.handleEditorChange}
													options={this.props.editors.split("|").map((editor) => ({
														value: editor,
														label: editor
													}))}
													placeholder={article.created_by}
													isSearchable={true}
													name="created_by"
												/>
											</form>
										</div>
									)}
								</div>
								{this.props.editMode &&
									JSON.stringify(article) !== JSON.stringify(this.state.savedArticle) && (
										<div>
											<button
												className="btn btn-primary btn-block"
												onClick={() => this.handleSave(false)}
												title="CMD/CTRL + S"
											>
												{article.status === "published" ? "Save" : "Save Unpublished"}
											</button>
											{article.status === "unpublished" && (
												<button
													style={{ marginTop: "1em" }}
													className="btn btn-secondary btn-block"
													onClick={() => this.handleSave(true)}
													title="CMD/CTRL + S"
												>
													Save &amp; Publish
												</button>
											)}
										</div>
									)}
							</aside>
						</main>
					)}
					<footer>
						{this.editMode && (
							<div className="article-information">
								<p>
									{article.views} Views | {article.revision_id} Edits
								</p>
							</div>
						)}
						{this.props.editMode && this.state.revisions && (
							<details className="article-history" open>
								<summary>History &rsaquo;</summary>
								<p>
									Created <time>{new Date(article.created_on).toLocaleDateString()}</time>
								</p>
								<ul>
									{this.state.revisions.map((h) => (
										<li key={h.revision_id}>
											V{h.revision_id}. <time>{new Date(h.updatedAt).toLocaleString()}</time> by{" "}
											{h._user && h._user.profile.given_name}{" "}
											{h._user && h._user.profile.family_name}{" "}
											<button
												onClick={() => {
													this.setState({
														currentArticle: h
													});
												}}
											>
												Load Version
											</button>
										</li>
									))}
								</ul>
							</details>
						)}
						{/* {!this.props.editMode && article.child_articles && article.child_articles.length > 0 && (
							<div>
								<h2>Child Articles</h2>
								<ul>
									{article.child_articles.map((slug, i) => {
										const fullArticle = this.props.articles.find((a) => a.slug === slug);
										return fullArticle ? (
											<li key={i} className="btn btn-secondary btn-block">
												<a href={"/wiki/" + slug}>{fullArticle.title}</a>
											</li>
										) : null;
									})}
								</ul>
							</div>
						)} */}
						{relatedArticles && relatedArticles.length > 0 && (
							<div>
								<h2>Related Articles</h2>
								<ul>
									{relatedArticles.map((article) => (
										<li key={article.slug} className="btn btn-secondary btn-block">
											<Link to={"/wiki/" + article.slug}>{article.title}</Link>
										</li>
									))}
								</ul>
							</div>
						)}
						{linkedArticles && linkedArticles.length > 0 && (
							<div>
								<h2>Links</h2>
								<ul>
									{linkedArticles.map((link) => (
										<li key={link} className="btn btn-secondary btn-block">
											<Link to={link}>{link}</Link>
										</li>
									))}
								</ul>
							</div>
						)}
					</footer>
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(ArticleWrapper);
