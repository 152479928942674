import styled from "styled-components";
export const BodyWrapperEl = styled.div`
	display: block;
	position: relative;
	& .header-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 60px;
		background: rgba(0, 0, 0, 0.75);
		backdrop-filter: blur(2px);
		border-bottom: 1px solid var(--bg-d0);
		z-index: 10;
		display: flex;
	}
	& .header-wrapper h1 {
		color: white;
		margin-left: 20px;
		font-size: 14px;
	}
	& .header-wrapper .right-wrapper {
		display: flex;
		position: absolute;
		top: 0;
		height: 100%;
		right: 0;
		min-width: 100px;
		padding: 0 12px;
		z-index: 99;
	}
	& .header-wrapper .right-wrapper .add-btn {
		color: var(--blue);
		height: 35px;
		margin-top: 12px;
		background: transparent;
		border: none;
		outline: none;
		border-radius: 3px;
		padding: 0 20px;
		font-size: 13px;
		transition: color 0.15s, -moz-transform ease-in-out 0.15s;
	}
	& .header-wrapper .right-wrapper .add-btn:hover {
		cursor: pointer;
		color: white;
	}
	& .body-wrapper {
		width: 100%;
		min-height: 500px;
		padding: 4em 4em 4em 19em;
	}
	.top-navigation::-webkit-scrollbar {
		display: none;
	}
	.top-navigation {
		/* background-color: rgba(30, 30, 30, 0.85); */
		overflow: visible;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		backdrop-filter: blur(3px);
		position: fixed;
		top: 0;
		transition: top 500ms ease;
		z-index:99;
		display: inline-flex;
		/* flex-wrap: wrap; */
		justify-content: start;
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
		overflow-x: auto;
		width: 100%;
		& > button {
			flex: 1 1;
			width: 100%;
		}
		.logo-button {
			padding: 1em;
		}
		.wiki-button {
			padding: 1em;
			.wiki-icon {
				padding: 0.5em;
				margin-right: 0.5em;
				background-image: var(--icon-wiki-lg);
				background-repeat: no-repeat;
				background-position: center;
			}
		}
		.icon-user {
			align-self: center;
			margin: 0.5em 1em 0 auto;
			border: 2px solid;
			button {
				padding: 0 !important;
			}
			img {
				width: 30px;
				height: 30px;
			}
		}
		.profile-button {
			background-repeat: no-repeat;
			background-size: cover;
			border: 2px solid;
			height: 3em;
			padding: 1em;
			flex: 0 0 2em;
			align-self: center;
			width: 3em !important;
			border-radius: 10em !important;
			margin: 1em;
			position: relative;
		}
		.theme-button {
			background-image: var(--icon-moon-g);
			background-repeat: no-repeat;
			border: none;
			padding: 1em 1em 1em 3em;
		}
		.menu-item {
			z-index: 9999999;
			align-content: center;
			height: 100%;
			.btn {
				flex: 1 1;
				width: 100%;
				text-transform: uppercase;
				border-radius: 0;
				
			}
			.btn-logo {
				padding: 0;
				opacity: 1 !important;
				height: 100%;
				display: flex;
				min-width: 11em;
				align-items: center;
			}
			.btn-logo img{
				height: 100%;
				padding: 8px;
			}
			.btn-logo a{
				font-family: "freight-display-pro", "Georgia", serif;
				opacity: 1;
				vertical-align: middle;
				height: 100%;
				display: inline-block;
			}
			a, .btn {
				text-decoration: none;
				color: inherit;
				opacity: 0.75;
				letter-spacing: 1px;
				word-break: break-word;
				&:hover {
					opacity: 1;
				}
			}
			.active {
				opacity: 1;
				border-bottom: 2px solid var(--violet);
			}


			&:last-child .sub-menu {
				right: 0;
			}
		}
		.sub-menu {
			z-index: 9999999999999999;
			min-width: 10em;
			max-width: 30em;
			font-size: 0.8em;
			position: absolute;
			left: -9999999em;
			z-index: 100;
			opacity: 0;
			transition: opacity 500ms ease;
			.icon {
				width: 1em;
				height: 1em;
				display: inline-block;
				margin-right: 0.5em;
			}
			ul {
				padding: 1em;
				box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
				border-radius: 1em;
				border: 1px solid rgba(0,0,0,0.1);
				margin-top: 1em !important;
				/* background: rgba(25, 25, 25, 0.95); */
				color:#333;
				background: rgba(255, 255, 255, 0.95);
				backdrop-filter: blur(3px);
			}
			a {
				display: block;
			}
			.btn {
				list-style-type: none;
				margin: 0;
				text-align: left;
				border-color: transparent;
			}
			li:not(:last-child){
				border-bottom: 1px solid rgba(0,0,0,0.1);
			}
		}
		.menu-item:hover .sub-menu {
			left: auto;
			opacity: 1;
		}
		.sub-menu:hover  {
			display: block;
			opacity: 1;
		}
		.sub-sub-menu {
			padding: 1em;
		}
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			li {
				padding: 0.1em 0;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.body-wrapper {
			padding: 4em 1em 6em 5em !important;
		}
	}
`;
