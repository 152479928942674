// Resources
// noinspection JSValidateTypes,RequiredAttributes

// Resources
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import * as linkify from "linkifyjs";
import linkifyHtml from "linkify-html";
import DOMPurify from "dompurify";
import Moment from "react-moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Highlighter from "react-highlight-words"; // https://github.com/bvaughn/react-highlight-words

// Components
import AuthHelperMethods from "../auth/AuthHelperMethods";
import TextareaAutosize from "react-textarea-autosize";

// Styles
import { ExampleWrapperEl } from "./styles/ExampleWrapper";

// Images
import cat from "../../images/animated/cat.webp";
import clear from "../../images/icons/v1/clear.svg";

class ExampleWrapper extends Component {
	Auth = new AuthHelperMethods();

	constructor(props) {
		super(props);
		this.state = {
			// Search
			tags_search: "",
			ex_search: "",
			// Lists
			tags_orig: [],
			tags: [],
			tags_user: [],
			examples_orig: [],
			examples: [],
			right_body_id: "",
			selected_example: undefined,
			// Tag
			create_tag_name: "",
			// Selections
			tags_selected: [],
			tags_selected_create: [],
			// Examples
			create_ex_desc: "",
			create_ex_links: "",
			create_ex_images: [],
			create_ex_valid: false,
			// Image upload
			processing: false,
			processingMsg: "",
			// Misc
			filterDropdownVisible: false
		};
		this.wrapperRef = React.createRef();
		this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	_onDrop = (files) => {
		if (files && files.length > 0) {
			// Validate file formats
			for (let i = 0; i < files.length; i++) {
				if (files[i].type !== "image/jpeg" && files[i].type !== "image/png") {
					return alert("Invalid file format detected");
				}
			}

			// Show processing prompt
			this.setState(
				{
					processing: true,
					processingMsg: ""
				},
				() => {
					// Kick off recursive function to upload images
					this.uploadSingleImage(files, 0, [], (imgs) => {
						this.setState(
							{
								processing: false,
								processingMsg: "",
								create_ex_images: this.state.create_ex_images.concat(imgs)
							},
							function () {
								this.validateCreateExample();
							}
						);
					});
				}
			);
		}
	};

	uploadSingleImage = (files, idx, imgs, cb) => {
		// Exit condition
		if (!files[idx]) return cb(imgs);
		// Perform actions
		this.setState(
			{
				processingMsg:
					files.length > 1 ? "Processing (" + (idx + 1) + "/" + files.length + ")" : "Processing..."
			},
			() => {
				const formData = new FormData();
				formData.append("image", files[idx]);
				formData.append("example_pre", "1");
				// Send file to server for upload
				this.Auth.uploadSingleImage(formData)
					.then(async (res) => {
						if (res && res.url) {
							imgs.push(res.url);
							setTimeout(() => {
								// Forward progress
								this.uploadSingleImage(files, idx + 1, imgs, cb);
							}, 1000);
						} else if (res.error) {
							console.error("[ uploadSingleImage ] RES ERROR ->", res.error);
						} else {
							console.error("[ uploadSingleImage ] UNKNOWN RESPONSE ->", res);
						}
					})
					.catch((err) => {
						console.error("[ uploadSingleImage ] CATCH ERROR ->", err);
					});
			}
		);
	};

	componentDidMount() {
		this.initData();
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	initData = (cb) => {
		this.Auth.initTags({ type: "example" })
			.then((res) => {
				if (res.error) {
					console.error(res.error);
				} else if (res.data) {
					console.log("[ ExampleWrapper - initTags ] data:", res.data);

					// Process tags
					let tags = [],
						tags_user = [];
					if (res.data.tags && res.data.tags.length > 0) {
						res.data.tags.forEach(function (el) {
							el.count = el._examples.length;
							if (el.sub_type && el.sub_type === "user") {
								tags_user.push(el);
							} else {
								tags.push(el);
							}
						});
					}
					tags.sort((a, b) => (a.count < b.count ? 1 : -1));
					tags_user.sort((a, b) => (a.count < b.count ? 1 : -1));

					// Process examples
					if (res.data.examples && res.data.examples.length > 0) {
						res.data.examples.forEach(function (el) {
							el._tags_ids = [];
							el._tags.forEach(function (tag) {
								el._tags_ids.push(tag._id);
							});
						});
					}

					this.setState(
						{
							tags_orig: tags,
							tags: tags,
							tags_user: tags_user,
							examples: res.data.examples,
							examples_orig: res.data.examples
						},
						function () {
							console.log(this.state.examples);
							console.log(this.state.tags);
							if (typeof cb === "function") cb();
						}
					);
				} else {
					console.error("Unknown Error");
				}
			})
			.catch((err) => {
				console.log("[ ExampleWrapper - initTags ] POST error:", err);
			});
	};

	_handleCreateButtonClicked = (type) => {
		this.exitCreateExample(() => {
			this.setState(
				{
					right_body_id: type,
					selected_example: undefined
				},
				function () {
					console.log(
						"[ ExampleWrapper - _handleCreateButtonClicked ] right_body_id:",
						this.state.right_body_id
					);
				}
			);
		});
	};

	_handleEnterPressed = (e) => {
		if (this.state.right_body_id === "tag" && e.key === "Enter") {
			this._handleCreateTagSubmit();
		}
	};

	_handleCreateTagNameChanged = (e) => {
		let cleanInput = "";
		if (e.target.value) cleanInput = e.target.value + "";
		cleanInput = cleanInput.toUpperCase();
		this.setState({
			[e.target.name]: cleanInput.replace(/[^\da-z_]/gi, "")
		});
	};

	_handleCreateTagSubmit = () => {
		if (this.state.create_tag_name.length === 0) {
			alert("ERROR: Tag name cannot be blank");
		} else {
			this.Auth.createTag({
				type: "example",
				name: this.state.create_tag_name
			})
				.then((res) => {
					if (res.error) {
						alert(res.error);
						this.setState({
							create_tag_name: ""
						});
					} else if (res.data) {
						let newList = this.state.tags;
						newList.push(res.data);
						this.setState({
							tags: newList,
							create_tag_name: ""
						});
					} else {
						alert("Unknown Error");
					}
				})
				.catch((err) => {
					console.log("[ ExampleWrapper - _handleCreateTagSubmit ] POST error:", err);
				});
		}
	};

	renderTags = () => {
		let items = "";
		if (this.state.tags.length > 0) {
			items = this.state.tags.map((item) => {
				return (
					<div
						className={
							"tag-btn noselect" +
							(this.state.tags_selected.indexOf(item._id) !== -1 ? " selected" : "") +
							(item.highlight ? " gradient-border" : "") +
							(this.state.tags_search && !item.highlight ? " dim" : "")
						}
						key={item._id}
						onClick={() => this._handleTagClicked(item._id)}
					>
						<p>
							{item.name} <span>{item._examples.length}</span>
						</p>
					</div>
				);
			});
		}
		return items;
	};

	renderTagsUser = () => {
		let items = "";
		if (this.state.tags_user.length > 0) {
			items = this.state.tags_user.map((item) => {
				return (
					<div
						className={
							"tag-btn user noselect" +
							(this.state.tags_selected.indexOf(item._id) !== -1 ? " selected" : "")
						}
						key={item._id}
						onClick={() => this._handleTagClicked(item._id)}
					>
						<p>
							{item.name} <span>{item._examples.length}</span>
						</p>
					</div>
				);
			});
		}
		return items;
	};

	_handleTagClicked = (id) => {
		if (this.state.tags_selected.indexOf(id) === -1) {
			// Add tag to list
			let newList = this.state.tags_selected;
			newList.push(id);
			this.setState(
				{
					tags_selected: newList
				},
				this.filterExamples
			);
		} else {
			// Remove tag from list
			let newList = [];
			this.state.tags_selected.forEach(function (el) {
				if (el !== id) newList.push(el);
			});
			this.setState(
				{
					tags_selected: newList
				},
				this.filterExamples
			);
		}
	};

	filterExamples = () => {
		if (this.state.tags_selected.length === 0) {
			this.setState({
				examples: this.state.examples_orig
			});
		} else {
			let filtered_list = [];
			this.state.examples_orig.forEach((example) => {
				for (let i = 0; i < this.state.tags_selected.length; i++) {
					if (example._tags_ids.indexOf(this.state.tags_selected[i]) !== -1) {
						// At least one of the selected tags are in the list
						filtered_list.push(example);
						break;
					}
				}
			});
			this.setState({
				examples: filtered_list
			});
		}
	};

	renderTagsCreate = () => {
		let items = "";
		if (this.state.tags.length > 0) {
			items = this.state.tags.map((item) => {
				return (
					<div
						className={
							"tag-btn noselect" +
							(this.state.tags_selected_create.indexOf(item._id) !== -1 ? " selected" : "")
						}
						key={item._id}
						onClick={() => this._handleTagClickedCreate(item._id)}
					>
						<p>{item.name}</p>
					</div>
				);
			});
		}
		return items;
	};

	_handleTagClickedCreate = (id) => {
		if (this.state.tags_selected_create.indexOf(id) === -1) {
			// Add tag to list
			let newList = this.state.tags_selected_create;
			newList.push(id);
			this.setState(
				{
					tags_selected_create: newList
				},
				this.validateCreateExample
			);
		} else {
			// Remove tag from list
			let newList = [];
			this.state.tags_selected_create.forEach(function (el) {
				if (el !== id) newList.push(el);
			});
			this.setState(
				{
					tags_selected_create: newList
				},
				this.validateCreateExample
			);
		}
	};

	_handleCreateExampleFieldChanged = (e) => {
		this.setState(
			{
				[e.target.name]: e.target.value
			},
			this.validateCreateExample
		);
	};

	_handleCreateExampleSubmit = () => {
		if (this.state.create_ex_valid) {
			// Build tags search array
			let tags_search = [];
			this.state.tags_selected_create.forEach((el) => {
				for (let i = 0; i < this.state.tags.length; i++) {
					if (el === this.state.tags[i]._id) {
						tags_search.push(this.state.tags[i].name);
						break;
					}
				}
			});

			const postData = {
				example_id: this.state.selected_example ? this.state.selected_example._id : undefined,
				description: this.state.create_ex_desc,
				links: this.state.create_ex_links,
				images: this.state.create_ex_images,
				_tags: this.state.tags_selected_create,
				tags_search: "#" + tags_search.join("#")
			};

			this.Auth.createExample(postData)
				.then((res) => {
					if (res.error) {
						alert(res.error);
					} else if (res.data) {
						this.exitCreateExample();
						this.initData(() => {
							this.filterExamples();
						});
					} else {
						alert("Unknown Error");
					}
				})
				.catch((err) => {
					console.log("[ ExampleWrapper - _handleCreateExampleSubmit ] POST error:", err);
				});
		}
	};

	validateCreateExample = () => {
		const valid =
			this.state.tags_selected_create.length > 0 &&
			this.state.create_ex_desc.length > 0 &&
			this.state.create_ex_links.length > 0 &&
			this.state.create_ex_images.length > 0;
		if (this.state.create_ex_valid !== valid) {
			this.setState({
				create_ex_valid: valid
			});
		}
	};

	exitCreateExample = (cb) => {
		this.setState(
			{
				selected_example: undefined,
				right_body_id: "",
				// Clear example info
				create_ex_desc: "",
				create_ex_links: "",
				create_ex_images: [],
				tags_selected_create: [],
				create_ex_valid: false
			},
			function () {
				if (typeof cb === "function") {
					cb();
				}
			}
		);
	};

	_handleExampleImgZoom = (e) => {
		e.stopPropagation();
		// Extract images array from example object
		let imgs = [];
		const exid = e.target.getAttribute("data-id");
		if (exid) {
			for (let i = 0; i < this.state.examples_orig.length; i++) {
				if (this.state.examples_orig[i]._id === exid) {
					imgs = this.state.examples_orig[i].images;
					break;
				}
			}
		}
		this.props.handleImageZoom(imgs);
	};

	renderExamples = () => {
		let items = "";
		if (this.state.examples.length > 0) {
			items = this.state.examples.map((item, idx) => {
				// Process links for reader mode
				let reader_content = item.links;
				const hasLink = linkify.find(item.links).length > 0;
				if (hasLink) {
					// Replace dangerous chars
					if (reader_content.indexOf("<") !== -1) reader_content = reader_content.replaceAll("<", "&lt;");
					if (reader_content.indexOf(">") !== -1) reader_content = reader_content.replaceAll(">", "&gt;");
				}
				return (
					<div
						className={
							"example-wrapper" +
							(this.state.selected_example && this.state.selected_example._id === item._id
								? " selected"
								: "")
						}
						key={item._id}
					>
						<div className={"img-wrapper"}>
							<div className="inner" data-id={item._id} onClick={this._handleExampleImgZoom}>
								<img src={item.images[0]} alt={item._id} />
							</div>
							{item.images.length > 1 && <div className={"img-count-widget"}>{item.images.length}</div>}
						</div>
						<div className={"info-wrapper"}>
							<div className={"tag-wrapper"}>
								{this.renderTagsExample(item._tags)}
								<div className={"name-wrapper"}>
									<p>
										{item.user_display_name} • <Moment fromNow>{item.createdAt}</Moment> •{" "}
										{item.clicks_num} views
									</p>
								</div>
							</div>
							<div className={"desc-wrapper"}>
								<Highlighter
									highlightClassName={"highlight"}
									searchWords={[this.state.ex_search]}
									autoEscape={true}
									textToHighlight={item.description}
								/>
							</div>
							{!item.show && (
								<div
									className={"links-show-wrapper noselect"}
									data-id={item._id}
									onClick={this._handleShowDetailsClicked}
								>
									Show Details &#8595;
								</div>
							)}
							{item.show && (
								<div className={"links-wrapper"}>
									{!hasLink && <p className="reader-text-box dont-break-out">{reader_content}</p>}
									{hasLink && (
										<p
											className="reader-text-box dont-break-out"
											dangerouslySetInnerHTML={{
												__html: DOMPurify.sanitize(
													linkifyHtml(reader_content, { defaultProtocol: "https" }),
													{ USE_PROFILES: { html: true } }
												)
											}}
										/>
									)}
								</div>
							)}
						</div>
						<div className={"actions-wrapper"}>
							<div className={"btn-wrapper edit"}>
								<div className={"btn"} onClick={() => this._handleExampleClicked(item)}>
									<div className={"edit-img"} />
								</div>
							</div>
							<div className={"btn-wrapper clap"}>
								{item.claps && item.claps.count > 0 && (
									<div
										className={"clap-count noselect"}
										onClick={() => console.log(item.claps.details)}
									>
										<p>{item.claps.count}</p>
									</div>
								)}
								<div className={"btn"} onClick={() => this._handleClapClicked(item, idx)}>
									<div className={"clap-img"} />
								</div>
							</div>
						</div>
					</div>
				);
			});
		}
		return items;
	};

	_handleShowDetailsClicked = (e) => {
		const exid = e.target.getAttribute("data-id");
		if (exid) {
			let updated_examples = this.state.examples;
			for (let i = 0; i < updated_examples.length; i++) {
				if (updated_examples[i]._id === exid) {
					updated_examples[i].show = true;
					updated_examples[i].clicks_num += 1;
				}
			}
			this.setState(
				{
					examples: updated_examples
				},
				() => {
					// Register view
					this.registerExampleView(exid);
				}
			);
		}
	};

	renderTagsExample = (tags) => {
		let items = "";
		if (tags.length > 0) {
			let ordered = [];
			tags.forEach(function (el) {
				if (el.sub_type) {
					ordered.unshift(el);
				} else {
					ordered.push(el);
				}
			});
			items = ordered.map((item) => {
				return (
					<div className={"tag-btn example"} key={item._id}>
						<p>{item.name}</p>
					</div>
				);
			});
		}
		return items;
	};

	_handleExampleClicked = (item) => {
		let tags = [];
		item._tags.forEach(function (el) {
			tags.push(el._id);
		});
		item.clicks_num += 1;
		this.setState(
			{
				selected_example: item,
				right_body_id: "example",
				// Fill in example info
				create_ex_desc: item.description,
				create_ex_links: item.links,
				create_ex_images: item.images,
				tags_selected_create: tags,
				create_ex_valid: false
			},
			() => {
				this.registerExampleView(item._id);
			}
		);
	};

	_handleClapClicked = (item, idx) => {
		// Set defaults
		if (!item.claps) {
			item.claps = {};
			item.claps.count = 0;
		}

		// Increment
		item.claps.count += 1;

		let userHandle = "";
		if (this.props.user && this.props.user.email) {
			userHandle = this.props.user.email.split("@")[0];
			if (!item.claps.details) item.claps.details = {};
			if (item.claps.details[userHandle]) {
				item.claps.details[userHandle] += 1;
			} else {
				item.claps.details[userHandle] = 1;
			}
		}

		// Make a shallow copy of examples, and update item
		let examples = [...this.state.examples];
		examples[idx] = item;

		// Make a shallow copy of examples_orig, and update item
		let examples_orig = [...this.state.examples_orig];
		for (let i = 0; i < examples_orig.length; i++) {
			if (examples_orig._id === item._id) {
				examples_orig.claps = item.claps;
			}
		}

		this.setState(
			{
				examples: examples,
				examples_orig: examples_orig
			},
			() => {
				this.updateClaps({
					example_id: item._id,
					user_handle: userHandle
				});
			}
		);
	};

	updateClaps = (data) => {
		this.Auth.updateClaps(data)
			.then((res) => {
				if (res.error) alert(res.error);
			})
			.catch((err) => {
				console.log("[ ExampleWrapper - updateClaps ] POST error:", err);
			});
	};

	registerExampleView = (exid) => {
		if (exid) {
			this.Auth.exampleClicked({ example_id: exid })
				.then((res) => {
					if (res.error) {
						alert(res.error);
					} else if (res.success) {
						// console.log('success');
					} else {
						alert("Unknown Error");
					}
				})
				.catch((err) => {
					console.log("[ ExampleWrapper - _handleExampleClicked ] POST error:", err);
				});
		}
	};

	_handleDeleteImage = (e) => {
		e.stopPropagation();
		const img = e.target.getAttribute("data-img");
		if (img && this.state.create_ex_images.length > 1) {
			let newList = [];
			this.state.create_ex_images.forEach(function (el) {
				if (el !== img) newList.push(el);
			});
			this.setState(
				{
					create_ex_images: newList
				},
				() => {
					this.validateCreateExample();
				}
			);
		}
	};

	_handleClearTags = () => {
		this.setState(
			{
				tags_selected: []
			},
			this.filterExamples
		);
	};

	_handleDeleteExampleClicked = () => {
		if (this.state.selected_example && this.state.selected_example._id) {
			confirmAlert({
				title: "Are you sure you want to permanently delete this Example?",
				message:
					"This action cannot be undone.",
				overlayClassName: "confirm-alert-bg-wrapper delete",
				buttons: [
					{
						label: "Yes, delete it!",
						onClick: () => this.deleteExample(this.state.selected_example._id),
						className: "red"
					},
					{
						label: "No, nevermind",
						onClick: () => console.log("_handleDeleteExampleClicked cancelled")
					}
				]
			});
		}
	};

	deleteExample = (id) => {
		this.Auth.deleteExample({ example_id: id })
			.then((res) => {
				if (res.error) {
					alert(res.error);
				} else if (res.success) {
					window.location.reload();
				} else {
					alert("Unknown Error");
				}
			})
			.catch((err) => {
				console.log("[ ExampleWrapper - deleteExample ] POST error:", err);
			});
	};

	_handleTagSearchChanged = (e) => {
		// Clear examples search
		if (this.state.ex_search) this.setState({ ex_search: "", examples: this.state.examples_orig });
		if (e.target.value) {
			const search = e.target.value.toUpperCase();
			let orig_copy = JSON.parse(JSON.stringify(this.state.tags_orig));
			if (search.length === 1) {
				// Filter by first letter
				orig_copy.forEach(function (tag) {
					if (tag.name[0] === search) {
						tag.highlight = true;
					}
				});
			} else {
				// Filter by string match
				orig_copy.forEach(function (tag) {
					if (tag.name.includes(search)) {
						tag.highlight = true;
					}
				});
			}
			this.setState({
				tags: orig_copy,
				tags_search: search
			});
		} else {
			// Clear filter
			this.setState({
				tags: this.state.tags_orig,
				tags_search: ""
			});
		}
	};

	_handleTagSearchCleared = () => {
		// Clear filter
		this.setState({
			tags: this.state.tags_orig,
			tags_search: ""
		});
	};

	_handleExSearchChanged = (e) => {
		// Clear tags filter
		if (this.state.tags_search) this.setState({ tags_search: "", tags: this.state.tags_orig });
		// Clear tags selection
		if (this.state.tags_selected.length > 0) this.setState({ tags_selected: [] });
		if (e.target.value) {
			// Filter results
			const search = e.target.value.toUpperCase();
			let orig_copy = JSON.parse(JSON.stringify(this.state.examples_orig));
			let filtered = [];
			// Filter by string match
			orig_copy.forEach(function (example) {
				if (example.description.toUpperCase().indexOf(search) !== -1) {
					filtered.push(example);
				}
			});
			this.setState({
				examples: filtered,
				ex_search: e.target.value
			});
		} else {
			this._handleExSearchCleared();
		}
	};

	_handleExSearchCleared = () => {
		this.setState({
			examples: this.state.examples_orig,
			ex_search: ""
		});
	};

	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
			// Hide the menu if it's visible
			if (this.state.filterDropdownVisible) {
				this.setState({ filterDropdownVisible: false });
			}
		}
	}

	_handleFilterTypeSelected = (e) => {
		e.stopPropagation();
		const type = e.target.getAttribute("data-id");
		let sorted = this.state.examples;
		if (type === "date") {
			sorted.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
		} else if (type === "views") {
			sorted.sort((a, b) => (a.clicks_num < b.clicks_num ? 1 : -1));
		} else if (type === "claps") {
			sorted.sort((a, b) => (a.claps.count < b.claps.count ? 1 : -1));
		}
		this.setState({
			examples: sorted,
			filterDropdownVisible: false
		});
	};

	render() {
		return (
			<React.Fragment>
				<ExampleWrapperEl>
					<div className={"ex-body-left-wrapper"}>
						<div className={"ex-title-wrapper"}>
							<div className={"ex-search-wrapper"}>
								<div className={"search-icon"} />
								{this.state.ex_search && (
									<div className={"clear-ex-search-button"} onClick={this._handleExSearchCleared}>
										<span>&#215;</span>
									</div>
								)}
								<input
									type={"text"}
									placeholder={"Search Examples"}
									name={"ex-search"}
									autoFocus={false}
									onChange={this._handleExSearchChanged}
									value={this.state.ex_search}
									autoComplete="off"
								/>
							</div>
							<div
								className={"create-btn-wrapper noselect"}
								onClick={() => this._handleCreateButtonClicked("example")}
							>
								<span
									className={
										!this.state.selected_example && this.state.right_body_id === "example"
											? "selected"
											: ""
									}
								>
									&#43;
								</span>
								<div className={"prompt-wrapper"}>
									<p>Create Example</p>
									<div className={"tip"} />
								</div>
							</div>
							<div className={"ex-num-wrapper"}>
								<p>
									{this.state.examples.length}/{this.state.examples_orig.length}
								</p>
							</div>
							<div
								className={"create-btn-wrapper noselect"}
								onClick={() => this.setState({ filterDropdownVisible: true })}
							>
								<div className={"filter-icon"} />
								<div className={"prompt-wrapper" + (this.state.filterDropdownVisible ? " hide" : "")}>
									<p>Filter Examples</p>
									<div className={"tip"} />
								</div>
								<div
									ref={this.wrapperRef}
									className={
										"filter-dropdown-wrapper" + (this.state.filterDropdownVisible ? " visible" : "")
									}
								>
									<div
										className={"filter-option-button"}
										data-id={"date"}
										onClick={this._handleFilterTypeSelected}
									>
										Order by Date
									</div>
									<div
										className={"filter-option-button"}
										data-id={"views"}
										onClick={this._handleFilterTypeSelected}
									>
										Order by Views
									</div>
									<div
										className={"filter-option-button"}
										data-id={"claps"}
										onClick={this._handleFilterTypeSelected}
									>
										Order by Claps
									</div>
								</div>
							</div>
						</div>
						<div className={"examples-body-wrapper"}>{this.renderExamples()}</div>
					</div>
					<div className={"ex-body-right-wrapper"}>
						{/* TAGS LIST */}
						<div className={"tags-main-wrapper"}>
							<div className={"tags-title-wrapper"}>
								<div className={"tags-search-wrapper"}>
									<div className={"search-icon"} />
									{this.state.tags_search && (
										<div
											className={"clear-tag-search-button"}
											onClick={this._handleTagSearchCleared}
										>
											<span>&#215;</span>
										</div>
									)}
									<input
										type={"text"}
										placeholder={"Filter Tags"}
										name={"tags-search"}
										autoFocus={false}
										onChange={this._handleTagSearchChanged}
										value={this.state.tags_search}
										autoComplete="off"
									/>
								</div>
								<div
									className={"create-btn-wrapper noselect"}
									onClick={() => this._handleCreateButtonClicked("tag")}
								>
									<span
										className={
											!this.state.selected_example && this.state.right_body_id === "tag"
												? "selected"
												: ""
										}
									>
										&#43;
									</span>
									<div className={"prompt-wrapper"}>
										<p>Create Tag</p>
										<div className={"tip"} />
									</div>
								</div>
								{this.state.tags_selected.length > 0 && (
									<span className="clear-tags-btn" onClick={this._handleClearTags}>
										Clear
									</span>
								)}
							</div>
							<div className={"tags-list-wrapper"}>
								{this.state.tags_user.length > 0 && <div>{this.renderTagsUser()}</div>}
								{this.state.tags.length > 0 && <div>{this.renderTags()}</div>}
							</div>
						</div>
						{this.state.right_body_id === "tag" && (
							<div
								className={
									"create-wrapper tag" + (this.state.create_tag_name.length > 0 ? " valid" : "")
								}
							>
								<h1>Create Tag</h1>
								<span
									className={"close-btn noselect"}
									onClick={() =>
										this.setState({
											right_body_id: "",
											create_tag_name: ""
										})
									}
								>
									&#215;
								</span>
								<div className={"input-body-wrapper"}>
									<div className={"input-title-wrapper"}>Tag Name:</div>
									<div className={"input-wrapper"}>
										<input
											value={this.state.create_tag_name}
											placeholder=""
											name="create_tag_name"
											type="text"
											onChange={this._handleCreateTagNameChanged}
											autoComplete="off"
											autoFocus={true}
											spellCheck="false"
											onKeyDown={this._handleEnterPressed}
										/>
									</div>
								</div>
								<div
									className={
										"create-submit-btn" + (this.state.create_tag_name.length > 0 ? " valid" : "")
									}
									onClick={this._handleCreateTagSubmit}
								>
									<p>Submit</p>
								</div>
							</div>
						)}
						{this.state.right_body_id === "example" && (
							<div className={"create-wrapper example" + (this.state.create_ex_valid ? " valid" : "")}>
								{this.state.selected_example &&
									(this.state.selected_example._user === this.props.user._id ||
										this.props.user.type === "sudo") && (
										<div
											className={"delete-btn noselect"}
											onClick={this._handleDeleteExampleClicked}
										>
											<img className="trash" src={clear} alt={"delete"} />
										</div>
									)}
								<h1>{this.state.selected_example ? "Edit Example" : "Create Example"}</h1>
								<span className={"close-btn noselect"} onClick={this.exitCreateExample}>
									&#215;
								</span>
								<div className={"input-body-wrapper"}>
									<div className={"input-title-wrapper area"}>Description:</div>
									<div className={"input-wrapper-area"}>
										<TextareaAutosize
											value={this.state.create_ex_desc}
											placeholder=""
											name="create_ex_desc"
											type="text"
											onChange={this._handleCreateExampleFieldChanged}
											autoComplete="off"
											autoFocus={true}
											spellCheck="false"
										/>
									</div>
								</div>
								<div className={"input-body-wrapper"}>
									<div className={"input-title-wrapper area"}>Link / Location:</div>
									<div className={"input-wrapper-area"}>
										<TextareaAutosize
											value={this.state.create_ex_links}
											placeholder=""
											name="create_ex_links"
											type="text"
											onChange={this._handleCreateExampleFieldChanged}
											autoComplete="off"
											autoFocus={false}
											spellCheck="false"
										/>
									</div>
								</div>
								<div className={"input-body-wrapper"}>
									<div className={"input-title-wrapper area"}>Tags:</div>
									<div className={"input-wrapper-area tags"}>{this.renderTagsCreate()}</div>
								</div>
								<div className={"add-image-body-wrapper"}>
									<div className={"upload-wrapper"}>
										{this.state.processing && (
											<div className="processing-wrapper">
												<img src={cat} alt={"cat"} />
												<p>{this.state.processingMsg}</p>
											</div>
										)}
										{!this.state.processing && (
											<Dropzone onDrop={this._onDrop}>
												{({ getRootProps, getInputProps }) => (
													<section className="dz-container">
														<div {...getRootProps({ className: "dropzone" })}>
															<input {...getInputProps()} />
															<p className="noselect">
																Drag & drop images here, or click to select files
															</p>
															<p className="noselect warning">
																Only .jpeg and .png files will be accepted
															</p>
														</div>
													</section>
												)}
											</Dropzone>
										)}
									</div>
									{this.state.create_ex_images && this.state.create_ex_images.length > 0 && (
										<div className={"images-wrapper"}>
											{this.state.create_ex_images.map((img) => (
												<div
													key={img}
													className="create-ex-image"
													onClick={() => this.props.handleImageZoom([img])}
												>
													<div className="inner">
														<img src={img} alt={img} />
													</div>
													{this.state.create_ex_images.length > 1 && (
														<div
															className={"img-delete-btn"}
															onClick={this._handleDeleteImage}
															data-img={img}
														>
															<p>&#215;</p>
														</div>
													)}
												</div>
											))}
										</div>
									)}
								</div>
								<div
									className={"create-submit-btn" + (this.state.create_ex_valid ? " valid" : "")}
									onClick={this._handleCreateExampleSubmit}
								>
									<p>Submit</p>
								</div>
							</div>
						)}
					</div>
				</ExampleWrapperEl>
			</React.Fragment>
		);
	}
}

export default ExampleWrapper;
